import React from "react"
import Layout from "../components/layout"
import * as cn from "classnames"
import ProductPreview from "../components/layout/ProductPreview"
import ListTitle from "../components/layout/ListTitle"
import { graphql } from 'gatsby'
import NoProductInfo from "../components/layout/NoProductInfo"
import {HelmetDatoCms} from "gatsby-source-datocms"

export default class SubCategoryList extends React.Component {
    state = {
        mode: "list"
    }

    changeMode(mode) {
        this.setState({mode: mode})
    }

    render() {
        const p = this.props, { } = p
        const s = this.state, { } = s
        const usage = p.data.usage
        const products = p.data.product.edges.filter(({node: product}) => product.subUsage.some( function (subElement) {
            return subElement.title === usage.title
        }))
        const sum = products.filter(n => n).length

        return (
            <Layout className="solid">
                <HelmetDatoCms>
                    <title>Falve - {usage.title}</title>
                    <meta name="description" content={usage.title}/>
                </HelmetDatoCms>
                <div className="breadcrumb">
                    <span>Zastosowanie</span>
                    <a href={`/usage/${usage.mainUsage.slug}`} title={usage.mainUsage.title}>{usage.mainUsage.title}</a>
                    <span>{usage.title}</span>
                </div>
                <div className="container">
                    <ListTitle title={usage.title} sum={sum} onClick={(mode) => this.changeMode(mode)} />
                    <div className={cn(s.mode)}>
                        {sum === 0 && <NoProductInfo />}
                        {sum > 0 && products.map(({ node: product }) => (
                            <ProductPreview product={product} />
                        ))}
                    </div>
                </div>
            </Layout>
        )
    }
}

export const query = graphql`
  query SubUsageQuery($slug: String!) {
    usage: datoCmsSubUsage(slug: { eq: $slug }) {
      title
      mainUsage {
        title
        slug
      }
    }
    product: allDatoCmsProduct {
      totalCount
      edges {
        node {
          title
          description
          slug
          subUsage {
            title
          }
          gallery {
           fluid(imgixParams: { auto: "compress" }) {
             ...GatsbyDatoCmsSizes
           }
          }
        }
      }
    }
  }
`